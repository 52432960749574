import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import autocomplete from 'devbridge-autocomplete';

class OfficePage extends Plugin {
  defaults() {
    return {
      mapContainerSelector: '[data-result="map"]',
      listContainerSelector: '[data-result="list"]',
      switcherSelector: "[data-result-switcher]",
      mapSwitcherSelector: '[data-result-switcher="map"]',
      listSwitcherSelector: '[data-result-switcher="list"]',
      officeCaptionInput: '[data-autocomplete="officeCaption"]',
      officeLocationInput: '[data-autocomplete="officeLocation"]',
      searchContainerSelector: `[data-expand="search-form"]`,
    };
  }

  buildCache() {
    this.resultType = "map";
    this.officeCaptionInput = this.element.querySelector(this.options.officeCaptionInput);
    this.officeLocationInput = this.element.querySelector(this.options.officeLocationInput);
    this.mapContainer = this.element.querySelector(
      this.options.mapContainerSelector
    );
    this.listContainer = this.element.querySelector(
      this.options.listContainerSelector
    );
    this.searchContainer = this.element.querySelector(
      this.options.searchContainerSelector
    );
    this.switcher = this.element.querySelectorAll(
      this.options.switcherSelector
    );
    this.mapSwitcher = this.element.querySelector(
      this.options.mapSwitcherSelector
    );
    this.listSwitcher = this.element.querySelector(
      this.options.listSwitcherSelector
    );
  }

  bindEvents() {
    for(let i=0; i<this.switcher.length; i++){
      const element = this.switcher[i];
      element.addEventListener("click", event => {
        this.switchResult.call(this, event);
      });
    }
    // this.switcher.forEach(element => {
    //   element.addEventListener("click", event => {
    //     this.switchResult.call(this, event);
    //   });
    // });
  }

  initAutocomplete() {
    if($(this.officeCaptionInput).length){
      $(this.officeCaptionInput).autocomplete({
        serviceUrl: 'http://localhost:3005/ajax/offices/names',
        onSelect: function (suggestion) {
          alert('You selected: ' + suggestion.value + ', ' + suggestion.data);
        }
      });
    }
    if($(this.officeLocationInput).length){
      $(this.officeLocationInput).autocomplete({
        serviceUrl: 'http://localhost:3005/ajax/offices/location',
        onSelect: function (suggestion) {
          alert('You selected: ' + suggestion.value + ', ' + suggestion.data);
        }
      });
    }

  }

  switchResult(event) {
    event.preventDefault();

    if (this.resultType == "list") {
      this.mapContainer.style.display = "block";
      this.mapContainer.classList.add('state_active');
      this.listContainer.style.display = "none";
      this.mapSwitcher.style.display = "none";
      this.listSwitcher.style.display = "flex";
      this.searchContainer.style.display = "block";
      this.resultType = "map";
    } else {
      this.mapContainer.style.display = "none";
      this.mapContainer.classList.remove('state_active');
      this.listContainer.style.display = "flex";
      this.mapSwitcher.style.display = "flex";
      this.listSwitcher.style.display = "none";
      this.searchContainer.style.display = "none";
      this.resultType = "list";
    }

    console.log("result switched", this.resultType);
  }

  init() {
    console.log("Offices Page Inited");
    this.initAutocomplete();
  }
}

export default init(OfficePage, "officePage");
