import Plugin, { init } from "@/modules/_utils/Plugin";
import scrollTo from "@/modules/_utils/scrollTo";
import $ from "jquery";

class ScrollTo extends Plugin {
  defaults() {
    return {
      duration: 700
    };
  }

  bindEvents() {
    this.element.addEventListener("click", event => {
      this.onClickHandler.call(this, event);
    });
  }

  onClickHandler(event) {
    event.preventDefault();
    let id = this.element.getAttribute("data-scroll-to");

    const options = { duration: this.options.duration };
    const to = $(document)
      .find('[data-scroll-target="' + id + '"]')
      .offset().top;

    console.log("scrollTo", to, options);
    $("body, html").animate(
      {
        scrollTop: to
      },
      this.options.duration
    );
  }
}

export default init(ScrollTo, "scrollTo");
