import Plugin, { init } from "@/modules/_utils/Plugin";
import * as parsley from "parsleyjs";
import $ from "jquery";
import PopupMessage from "../popup-message/popup-message";
import Dropzone from "dropzone";

class FileUpload extends Plugin {
  defaults() {
    return {
      uloadLinkAttribute: "data-link",
      dropZoneSelector: ".dropzone",
      introIcon: "[data-intro-icon]",
      hiddenInput: '[name="attachedFile"]'
    };
  }

  buildCache() {
    this.dropzone = this.element.querySelector(".dropzone");
    this.uploadUrl = this.dropzone.getAttribute(
      this.options.uloadLinkAttribute
    );
    this.introIcon = this.element.querySelector(this.options.introIcon);
    this.hiddenInput = this.element.querySelector(this.options.hiddenInput);
    this.myDropzone = null;
    this.informer = new PopupMessage();
  }

  bindEvents() {}

  init() {
    
    Dropzone.autoDiscover = false;
    this.myDropzone = new Dropzone(this.element, {
      url: this.uploadUrl,
      headers: {
        // remove Cache-Control and X-Requested-With
        // to be sent along with the request
        // TODO: remove for prod
        "Cache-Control": null,
        "X-Requested-With": null
      },
      maxFilesize: 10,
      uploadMultiple: false,
      createImageThumbnails: false,
      maxFiles: 1,
      clickable: true,
      addRemoveLinks: true,
      previewsContainer: this.element.querySelector(".dropzone"),
      previewTemplate: `
            <div class="dz-preview dz-file-preview">
            <div class="dz-error-message">Ошибка загрузки</div>
            <div class="dz-success-mark">Успешно!</div>
            <div class="dz-progress">
                <svg class="progress-image" width="72" height="72" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle r="33" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
                <circle class="progress-circle" r="33" cx="50%" cy="50%" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0" style="stroke-dashoffset:565.48px;"></circle>
            </svg>
            </div>
            <div class="dz-details">
                <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            </div>`,
      // accept: function(file, done) {
      //   done();
      // },
      uploadprogress: (file, progress, bytesSent) => {
        this.setProgress(
          progress,
          this.element.querySelector(".progress-circle")
        );
      },
      sending: (file, xhr, formData) => {
        this.introIcon.style.display = "none";
        formData.append("sessId", document.querySelector('[data-sessid]').value);
      },
      error: (file, err) => {
        this.myDropzone.removeFile(file);
        this.informer.showMessage('Ошибка', err);
        this.introIcon.style.display = "block";
        if (this.element.querySelector(".dz-preview"))
          this.element.querySelector(".dz-preview").remove();
        this.element.querySelector('input[type="hidden"]').value = '';
      },
      removedfile: () => {
        this.introIcon.style.display = "block";
        this.element.querySelector(".dz-preview").remove();
        this.element.querySelector('input[type="hidden"]').value = '';
      },
      maxfilesexceeded: function(file) {
        console.log(1);
        this.removeAllFiles();
        this.addFile(file);
      },
      complete: (file, res) => {
        if (this.element.querySelector(".dz-remove"))
          this.element.querySelector(".dz-remove").innerHTML =
          '<div class="sprite icon-ico-050"></div>';
        console.log(res);
      },
      success: (file, response) => {
        console.log(response);
        if (response.status == 200) {
          this.element.querySelector('input[type="hidden"]').value = response.path;
        } else {
          this.myDropzone.removeFile(file);
          this.informer.showMessage('Ошибка', response.error);
        }
        if (response.status == 200) {
          return file.previewElement.classList.add("dz-success");
        }
        return file.previewElement;
      }
    });
    console.log("Dropzone Inited", this.myDropzone);
  }

  setProgress(percent, circle) {
    percent = parseInt(percent);
    if (isNaN(percent)) {
      percent = 100;
    } else {
      const r = circle.getAttribute("r");
      const c = Math.PI * (r * 2);
      if (percent < 0) {
        percent = 0;
      }
      if (percent > 100) {
        percent = 100;
      }
      const pct = ((100 - percent) / 100) * c;
      circle.style.strokeDashoffset = pct;
    }
  }
}

export default init(FileUpload, "fileUpload");
