class Preloader {
    constructor() {
        this.showOn = null;
        this.preloader = null;
        this._createBlock();
    }
    _createBlock(){
        this.preloader = document.createElement('div');
        this.preloader.classList.add('preloader');
        this.preloader.setAttribute('data-preloader','data-preloader');
        this.preloader.style.display = 'block';
        this.preloader.innerHTML = '<div class="preloader__wr"><div class="preloader__obj"></div></div>';
    }
    show(onElement){
        this.showOn = onElement;
        onElement.appendChild(this.preloader);
    }
    hide(){
        if(this.showOn){
            try {
                this.showOn.removeChild(this.preloader);
            } catch (e) {
                
            }
        } else {
            try {
                document.body.removeChild(this.preloader);
            } catch (e) {

            }

        }
    }
}
export default Preloader;
