import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";

class ArrowTop extends Plugin {
    defaults() {
        return {

        };
    }

    init() {
        this.createArrow();
        this.initEvents();
    }
    createArrow(){
        this.arrow = document.createElement('div');
        this.arrow.classList.add('arrow-top');
        this.arrow.classList.add('js-arrow-top');
        const arrow = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="isolation:isolate" viewBox="0 0 284.928 166.161" width="284.928" height="166.161"><path d=" M 282.082 135.901 L 149.028 2.856 C 147.127 0.953 144.94 0 142.466 0 C 139.992 0 137.801 0.953 135.899 2.856 L 2.856 135.901 C 0.95 137.807 0 139.994 0 142.469 C 0 144.943 0.953 147.133 2.856 149.035 L 17.128 163.306 C 19.031 165.209 21.221 166.16 23.695 166.16 C 26.169 166.16 28.359 165.209 30.262 163.306 L 142.466 51.104 L 254.674 163.313 C 256.576 165.216 258.767 166.161 261.237 166.161 C 263.715 166.161 265.905 165.21 267.807 163.313 L 282.081 149.036 C 283.983 147.134 284.928 144.943 284.928 142.47 C 284.929 139.994 283.984 137.804 282.082 135.901 Z " fill="rgb(0,0,0)"/></svg>`;
        this.arrow.innerHTML = arrow;
        document.body.appendChild(this.arrow);
        }
    initEvents(){
        this.arrow.addEventListener('click', () => {
            this.scrollUp();
        },false);
        window.addEventListener('scroll',() => {

            let top = Math.max(document.body.scrollTop,document.documentElement.scrollTop);
            if(top > 400){
                if(!this.arrow.classList.contains('state_show')){
                    this.arrow.classList.add('state_show');
                }
            } else {
                if(this.arrow.classList.contains('state_show')){
                    this.arrow.classList.remove('state_show');
                }
            }
        });
    }
    scrollUp() {
        function up(){
            let t;
            let top = Math.max(document.body.scrollTop,document.documentElement.scrollTop);
            if(top > 0) {
                window.scrollBy(0,((top+100)/-10));
                t = setTimeout(up,20);
            } else clearTimeout(t);
        }
       up();
    }

}

export default init(ArrowTop, "ArrowTop");
