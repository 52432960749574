import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import toArray from "@/modules/_utils/dom/toArray";

import $ from "jquery";
import "magnific-popup";

import PopupMessage from "../popup-message/popup-message";

class ZoomImage extends Plugin {
  defaults() {
    return {
      
    };
  }

  init() {
      console.log('zoomImage inited');
    $(this.element).magnificPopup({
        type: 'image'
    });
  }

}

export default init(ZoomImage, "zoomImage");