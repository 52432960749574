import $ from 'jquery';

var preloaderSelector = `[data-preloader]`;
var speed = 'slow';

export function showPreloader() {
    $('.header').css('z-index', 'auto');
    $(preloaderSelector).fadeIn(speed);
    
}

export function hidePreloader() {
    $(preloaderSelector).fadeOut(speed, function() {
        $('.header').css('z-index', '');
    });
} 