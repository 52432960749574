import debounce from "lodash/debounce";
import Plugin, {init} from "@/modules/_utils/Plugin";
import toArray from "@/modules/_utils/dom/toArray";

import $ from "jquery";

import PopupLocation from "../popup-location/popup-location";
import tippy from 'tippy.js';

class TopService extends Plugin {
  defaults() {
    return {
      locationSelector: '[data-choose-location]',
      tooltipSelector: '[data-help-tooltip]',
    };
  }

  init() {
    this.initTooltipCreation();
  }

  buildCache() {
    this.location = this.element.querySelector(this.options.locationSelector);
    this.tooltipSelector = this.element.querySelector(this.options.tooltipSelector);
    this.instance = null;
  }

  bindEvents() {
    this.location.addEventListener("click", event => {
      this.openLocationPopup.call(this, event);
    });

    this.tooltipSelector.addEventListener("touchstart", () => {
      this.instance.show();
    });
  }

  initTooltipCreation() {
    const title = `
                    Портал пациента — это сайт-партнёр портала госуслуг. Чтобы войти в личный кабинет и пользоваться
                    нашими сервисами, подтвердите регистрацию на госуслугах. А если вы там ещё не зарегистрированы, можете
                    <a href="https://esia.gosuslugi.ru/registration/" class="tooltip-link"><span>сделать это сейчас</span></a>. 
                  `;

    tippy(this.tooltipSelector, {
      content: title,
      placement: 'left-start',
      theme: 'light',
      animation: 'shift-toward',
      interactive: true,
    });

    this.instance = this.tooltipSelector._tippy;
  }

  openLocationPopup(event) {
    event.preventDefault();
    let pl = new PopupLocation();
    pl.showModal();
  }

}

export default init(TopService, "topService");
