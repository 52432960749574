import Plugin, { init } from "@/modules/_utils/Plugin";
import * as parsley from "parsleyjs";
import $ from "jquery";
import "magnific-popup";
class PopupLocation {
  constructor() {
    this.sourcePopup = "#popupLocation";
  }

  showModal() {
    $.magnificPopup.open({
      items: {
        src: this.sourcePopup,
        type: "inline",
        modal: true
      },
      closeMarkup: `<button title="Закрыть" type="button" class="mfp-close"><span class="sprite icon-ico-050"></span></button>`,
      callbacks: {
        open: function() {
          $('.mfp-close span').on('click', () => {
            console.log('click on span');
            $.magnificPopup.close();  
          });
        }
      }
    });
  }
}
export default PopupLocation;
