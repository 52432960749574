import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";

class Share extends Plugin {
    defaults() {
        return {

        };
    }

    init() {
        let shareBlock = document.body.querySelectorAll('.ya-share2');
        if(shareBlock.length){}
        this.loadLib('//yastatic.net/es5-shims/0.0.2/es5-shims.min.js').then(()=>{
            this.loadLib('//yastatic.net/share2/share.js');
        });
    }
    static createScript(url){
        const script=document.createElement('script');
        script.type='text/javascript';
        document.body.appendChild(script);
        script.src=url;
        script.async=true;
        return script;
    }
    loadLib(pathToJs){
        return new Promise((resolve,reject)=>{
            const script=Share.createScript(pathToJs);
            script.addEventListener('load',function () {
                resolve(script);
            },false);
            script.error =function () {
                console.error('ошибка зкгрузки '+pathToJs);
                reject();
            };
        });
    }



}

export default init(Share, "Share");
