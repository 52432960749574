import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import Preloader from '@/modules/preloader/preloader';

class Geocode extends Plugin {
    defaults() {
        return {
            urlAttr: "data-url",
        };
    }
    init() {
        this.preloader = new Preloader();
        this.preloader.show( $('.js-popup-location__layout')[0]);
        this.data = [];
        this.cacheLiveTime = 360000;
        console.log("init");
        const cityString  = window.localStorage.getItem('city');
        const cacheTime = window.localStorage.getItem('cacheTime');
        let cityData;
        if(cityString){
            cityData = JSON.parse(cityString);
            this.setCity(cityData.name);

        } else {
            ymaps.ready().then(() => {
                this.getLocation();
            });
        }
        const cityListString  = window.localStorage.getItem('cityList');
        if(cityListString){
            if(cacheTime){
                let cache = parseInt(cacheTime);
                let now = new Date().getTime();
                console.log(cache ,now);
                if(cache < now){
                    this.popupDataInit();
                } else{
                    this.data = JSON.parse(cityListString);
                    this.setPopupData();
                }
            } else {
                this.popupDataInit();
            }


        } else {
            this.popupDataInit();
        }

    }

    popupDataInit(){
        console.log("refresh");
        window.localStorage.removeItem('cityList');
        this.data = [];
        ymaps.ready().then(() => {
            $.ajax({ url: this.url }).done(result => {
                this.getData(result).then(()=>{
                    this.setPopupData();
                });
            });
        });
    }

    setPopupData(){
        const popupBlock = $('.js-popup-location__layout');
        popupBlock.html('');
        this.data.sort((a, b)=>{
            const nameA=a.name.toLowerCase(),
                nameB=b.name.toLowerCase();

            if (nameA < nameB)
                return -1;
            if (nameA > nameB)
                return 1;
            return 0;
        });
        this.data.map((item) => {
           const li = document.createElement('li');
           li.classList.add('popup-location__item');
           li.classList.add('js-popup-location__item');
           li.setAttribute('data-coords',item.coords.join(','));
           li.innerHTML =`<a class="popup-location__link" href="#">${item.name}</a>`;
           popupBlock.append($(li));
           li.addEventListener('click',(e) => {
               window.localStorage.setItem('city',JSON.stringify({
                   name: $(e.currentTarget).text(),
                   coords: $(e.currentTarget).data('coords'),
               }));
               this.setCity($(e.currentTarget).text());
               $('.mfp-close').trigger('click');
           },false);
        });
        this.preloader.hide();
        window.localStorage.setItem('cacheTime',+new Date().getTime()+ this.cacheLiveTime);
    }

    getData(data){
        return new Promise((resolve,reject) => {
            const self = this;
            let i = 0;
            data.map(() => {
                this.data.push({
                    name: null,
                    coords: null,
                });
            });
            setNext();
            function setData(i,cityName,call){
                const index = i;
                const name = cityName;
                const search = 'Краснодарский край '+name;
                return ymaps.geocode(search , { results: 1 }).then(function(res){
                    try{
                        const cords = res.geoObjects.get(0).geometry.getCoordinates();
                        self.data[index] = {
                            name: name,
                            coords: cords,
                        };
                    } catch (e) {}
                    return call? call(): true;
                });
            }
            function setNext(){
                setData(i,data[i].region,()=>{
                    if(i<data.length){
                        setNext();
                    } else {
                        window.localStorage.setItem('cityList',JSON.stringify(self.data));
                       resolve();
                    }
                    i++;
                });
            }
        });
    }


    buildCache() {
       this.url = this.element.getAttribute(this.options.urlAttr);
       this.cityName = $('#cityName');
       this.cityNameShort = $('#cityNameShort');
    }
    getLocation() {
        this.location = ymaps.geolocation;
        this.location.get({
            // mapStateAutoApply: true,
            provider: 'yandex'
        })
            .then(
                result => {
                    const data = result.geoObjects.get(0).properties.getAll();
                    this.setCity(data.name);
                    window.localStorage.setItem('city',JSON.stringify({
                        name: data.name,
                        coords: result.geoObjects.get(0).geometry.getCoordinates(),
                    }));
                },
                err => {
                    console.log('Ошибка!', err);
                }
            );

    }
    setCity(name){
        this.cityName.html(name);
        this.cityNameShort.html(name);
    }
}

export default init(Geocode, "geocode");
