import Plugin, { init } from "@/modules/_utils/Plugin";
import * as parsley from "parsleyjs";
import $ from "jquery";
import "magnific-popup";
class PopupMessage {
  constructor() {
    this.sourcePopup = "#popupMessage";
  }

  showMessage(title, message) {
    $.magnificPopup.open({
      items: {
        src: this.sourcePopup,
        type: "inline"
      },
      closeMarkup: `<button title="Закрыть" type="button" class="mfp-close"><span class="sprite icon-ico-050"></span></button>`,
      callbacks: {
        open: function() {
          $('.mfp-close span').on('click', () => {
            console.log('click on span');
            $.magnificPopup.close();  
          });
          $(document)
            .find('[data-message="title"]')
            .text(title);
          $(document)
            .find('[data-message="message"]')
            .text(message);
        }
      }
    });
  }
}
export default PopupMessage;
