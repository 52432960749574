import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import ApoitmentSteps from './apointment-page-steps';
import PopupMessage from "../popup-message/popup-message";
import * as datepicker from "bootstrap-datepicker";
import * as parsley from "parsleyjs";
import Inputmask from "inputmask";
import Preloader from '@/modules/preloader/preloader.js';
class AppointmentPage extends Plugin {
    defaults() {
        return {
          element: '[data-appointment-form]',
          template: '[data-template]',
          datepicker: "#datePicker",
          infoTemplate :`<p><b>##FIO##, ##BIRTH## г.р.</b></p>
                         <p>Запись на приём ##DATE##</p>
                         <p>##DOCTORTYPE##: <b>##DOCTOR##</b></p>
                         <p>##HOSPITAL##</p>`,
          ticketTemplate: `<div class="appointment-ticket__col">
                                    <p><b> <span data-result="client-name">##FIO##</span>, <span data-result="client-birthday">##BIRTH##</span>г.р.</b></p>
                                    <p>Запись на приём <b data-result="date-time">##DATE##</b></p>
                                    <p><span data-result="doctor-type">##DOCTORTYPE##</span> <b data-result="doctor-name">##DOCTOR##</b></p>
                                </div>
                                <div class="appointment-ticket__col">
                                    <div class="appointment-ticket__clinik" data-result="hospital-title">##HOSPITAL##</div>
                            </div>
                            `,
           responseStruct: {
                      id: ['id','iddoc','idspesiality'],
                      ticketNum: ['countfreeticket'],
                      time: ['idappointment'],
                },
        };
    }
    init() {
        this._validateForm();
        this._initDatePicker();
        this.preloader = new Preloader();
    }
    buildCache() {
        this.formData = {};
        this.calendarData = [];
        this.steps = new ApoitmentSteps('[data-appointment-form]')[0];
        this.currentStep = this.steps.getcurrentStepElement();
        this.actionNum = this._createInput(this.element,'action',this.steps.currentStep);
        this.datePickerEl = this.element.querySelector(this.options.datepicker);
    }
    bindEvents() {
        const bindedInput = this._createInput(this.element,$('[data-template-parent="selectedRegion"]').attr('data-template-parent'),0);
        $('.js-appointment-choose-region__item').on('click', (e)=>{
            const element = $(e.currentTarget);
            bindedInput.value = element.data('step-result');
            this._sendData(this.currentStep.step.find('[data-url]').attr('data-url')).then(data => {
               this._submitHundler(data);
            });
        });
        $('[data-finish-appointment]').on('click', (e)=>{
            if(!$(e.currentTarget).hasClass('state_disabled')){
                this.actionNum.value = 6;
                this._createInput(this.element,'hospitalName',String(this.formData.hospital.trim()));
                this._createInput(this.element,'doctorName', this.formData.doctor);
                this._sendData(this.currentStep.step.find('[data-url]').attr('data-url')).then(data => {
                    this._submitHundler(data);
                    this.steps.resetEditable();
                });
            }
        });
        $('[data-reset-appointment]').on('click', ()=>{
            document.location.reload();
        });

        $('.js-appointment-ticket__button').on('click',(e) => {
            e.preventDefault();

        });


        document.addEventListener('stepchange',()=> {
            this.currentStep = this.steps.getcurrentStepElement();
            this.actionNum.value = this.steps.currentStep;
        },false);
    }
    generateContent(d){
        let data ;
        if(!Array.isArray(d)){
            let dataArray = [];
            dataArray.push(d);
            data = dataArray;
        } else {
            data = d;
        }
        try {
            const template = $(this.currentStep.body.find(this.options.template)[0].cloneNode(true));
            const templateParent = this.currentStep.body.find(this.options.template).closest('[data-template-parent]');
            templateParent.html('');
            const inputName =  templateParent.attr('data-template-parent');
            const bindedInput = this._createInput(this.element,inputName,0);
            for(let i=0; i<data.length; i++) {
                const item = data[i];
                templateParent.append(this._generateItem(template,item,(e) => {
                    const element = $(e.currentTarget);
                    if(!element.hasClass('js-time-select')){
                        e.preventDefault();
                    } else {
                        this.formData.time = element.find('label').text();
                    }
                    element.bindedInput = bindedInput;
                    let value = element.find('[data-step-result]').data('step-result');
                    if(!value){
                        value = element.data('step-result');
                    }
                        element.bindedInput.value = value;
                    this.formData[inputName] = value;
                    if(!element.hasClass('js-time-select')){
                        this._sendData(this.currentStep.step.find('[data-url]').attr('data-url')).then(data => {
                            this._submitHundler(data);
                        });
                    } else {
                        $('[data-finish-appointment]').removeClass('state_disabled');
                        this._timeSelectHundler();
                    }
                    if(element.hasClass('js-appointment-medical-institution__item')){
                        this.formData.hospital = element.text();
                    }
                    if(element.hasClass('js-appointment-doctor-type__item')){
                        this.formData.docType = element.find('[data-value-block="NameSpesiality"]').text();
                    }
                    if(element.hasClass('js-appointment-doctor__item')){
                        this.formData.doctor = element.find('[data-value-block="Name"]').text();
                    }
                }));
            }
        } catch (e) {
            console.error('error');
        }
        this.actionNum.value = this.steps.currentStep;
    }
    _submitHundler(data){
            this.steps.setStepEditable(this.steps.currentStep);
            this.steps.nextStep();

            if(this.steps.currentStep === 2){
                this.currentStep = this.steps.getStepElementByNum(this.steps.currentStep+1);
            } else {
                this.currentStep = this.steps.getcurrentStepElement();
            }
            if(this.steps.currentStep === 5){
                $('.js-appointment-date-time__summary').html('');
                $('.js-time-select').addClass('state_no-active');
                $('[data-finish-appointment]').addClass('state_disabled');
                this.calendarData = data;
                for(let i=0; i<this.calendarData.length; i++){
                    this.calendarData[i] = AppointmentPage._parseDateTimeString(this.calendarData[i]);
                }
                $(this.datePickerEl).datepicker('update', '');
                $(this.datePickerEl).datepicker('setStartDate',  new Date(this.calendarData[0][0][0],this.calendarData[0][0][1]-1,this.calendarData[0][0][2]));
            } else {
                this.generateContent(data);
            }
    }
    _timeSelectHundler(){
        const fio = $('.js-family').val() + ' '+ $('.js-name').val() +' '+ $('.js-sirname').val();
        const birth = $('.js-birth').val();
        const d = this.formData.date? this.formData.date.toLocaleDateString() : '';
        const t = this.formData.time? this.formData.time : '';
        const date = d+' '+ t;
        $('.js-appointment-date-time__summary').html(this._generateDoctorInfo(fio,birth,date,this.formData.docType,this.formData.doctor,this.formData.hospital));
        $('.js-appointment-ticket__summary').html(this._generateTicket(fio,birth,date,this.formData.docType,this.formData.doctor,this.formData.hospital));

    }
    _generateDoctorInfo(fio,birth,date,type,doctor,hospital){
        let html = this.options.infoTemplate;
        html =String(html).replace('##FIO##',fio);
        html =String(html).replace('##BIRTH##',birth);
        html =String(html).replace('##DATE##',date);
        html =String(html).replace('##DOCTORTYPE##',type);
        html =String(html).replace('##DOCTOR##',doctor);
        html =String(html).replace('##HOSPITAL##',hospital);
        return html;
    }
    _generateTicket(fio,birth,date,type,doctor,hospital){
        let html = this.options.ticketTemplate;
        html =String(html).replace('##FIO##',fio);
        html =String(html).replace('##BIRTH##',birth);
        html =String(html).replace('##DATE##',date);
        html =String(html).replace('##DOCTORTYPE##',type);
        html =String(html).replace('##DOCTOR##',doctor);
        html =String(html).replace('##HOSPITAL##',hospital);
        return html;
    }
    _isValuedKey(key,item, data){
        for(let i=0; i<this.options.responseStruct.id.length; i++){
            if(String(key).toLowerCase() === this.options.responseStruct.id[i]){
                item.find('[data-step-result]').attr('data-step-result',data[key]);
                item.attr('data-step-result',data[key]);
                return true;
            }
        }
        return false;
    }
    _isTimedKey(key,item, data){
        if(item.hasClass('js-time-select')){
            const id = AppointmentPage._generateId();
            item.find('input').attr('id',id);
            item.find('label').attr('for', id);
        }
        for(let i=0; i<this.options.responseStruct.id.length; i++){
            if(String(key).toLowerCase() === this.options.responseStruct.time[i]){
                item.find('input').attr('data-step-result',data[key]);
                item.attr('data-step-result',data[key]);
                item.find('input').val(data['VisitStart']);
                return true;
            }
        }
        return false;
    }
    _isTickedKey(key,item,data){
        for(let i=0; i<this.options.responseStruct.ticketNum.length; i++){
            if(String(key).toLowerCase() === this.options.responseStruct.ticketNum[i]){
                if(data[key]===0){
                    item.addClass('state_no-active');
                    return true;
                }
            }
        }
        return false;
    }
    _generateItem(template,data,listener){
        const item = $(template[0].cloneNode(true));
        Object.keys(data).forEach((key) => {
            const valueBlock = item.find(`[data-value-block="${key}"]`);
            valueBlock.parent().removeClass('state_no-active');
            if(data[key]){
                valueBlock.html(AppointmentPage._addbreakLine(data[key]));
            } else {
                valueBlock.parent().addClass('state_no-active');
                valueBlock.html(data[key]);
            }
            this._isValuedKey(key,item,data);
            this._isTickedKey(key,item,data);
            this._isTimedKey(key,item,data);

        });
        if(listener){
            if(!item.hasClass('state_no-active')){
                item.on('click',listener);
            }
        }
        return item;
    }
    static _addbreakLine(str){
        return String(String(str).replace('(','<br>(')).replace(')',')<br>');

    }
    static _generateId(){
        return '_' + Math.random().toString(36).substr(2, 9);
    }
    _sendData(url){
        this.preloader.show(document.body);
        let queryResult = $(this.element).serialize();
        return new Promise((resolve,reject) => {
            $.ajax({
                url: url,
                type: "POST",
                data: queryResult
            }).done(data => {
                this.preloader.hide();
                if(data.status === 400) {
                    let pm = new PopupMessage();
                    pm.showMessage('Ошибка!', data.error);
                    reject(data);
                } else {
                    resolve(data);
                }
            }).fail(data => {
                    this.preloader.hide();
                    let pm = new PopupMessage();
                    pm.showMessage('Ошибка!', "Сервис временно недоступен!");
                    reject(data);
            });
        });
    }
    _createInput(form,name,value){
        if($(form).find(`[name="${name}"]`).length){
            $(form).find(`[name="${name}"]`).val(value);
            return $(form).find(`[name="${name}"]`)[0];
        }
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
        return input;
    }
    _validateForm(){
        $(this.element).parsley()
            .on("form:submit", () => {
                try {
                    this._sendData($('.js-appointment-personal-data').attr('data-url')).then((data)=>{
                        if (data.status == 200) {
                            this.steps.setStepEditable(this.steps.currentStep);
                            this._createInput(this.element,'patId',data.patId);
                            this.steps.nextStep();
                            this.currentStep = this.steps.getcurrentStepElement();
                            this.actionNum.value = this.steps.currentStep;
                        } else if (data.status == 400) {
                            let pm = new PopupMessage();
                            pm.showMessage('Ошибка!', data.error);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
                return false;
            });
    }
    _initDatePicker() {
        AppointmentPage._setDatePickerSettings();
        $(this.datePickerEl).datepicker({
            language: "ru-RU",
            inline: true,
            viewMode: "days",
            format: "DD.MM.YYYY",
            beforeShowDay: (date) => {
                if(this.calendarData.length>0) {
                    for(let i=0; i<this.calendarData.length; i++){
                        const calendarDate = this.calendarData[i];
                        let d = new Date(calendarDate[0][0],calendarDate[0][1]-1,calendarDate[0][2]);
                        if(AppointmentPage._getDateString(date) === AppointmentPage._getDateString(d)){
                            return {
                                enabled: true,
                                classes: 'state_active',
                                tooltip: '',
                                content: '',
                            };
                        }
                    }
                    return  {
                        enabled: false,
                        classes: 'state_disabled',
                        tooltip: '',
                        content: '',
                    };
                }
                else
                    return  {
                    enabled: false,
                    classes: 'state_disabled',
                    tooltip: '',
                    content: '',
                };
            }
        });
        $(this.datePickerEl).datepicker()
            .on("changeDate", (e) => {
                this._createInput(this.element, 'userSelectedDate', AppointmentPage._getDateString(e.date)+'T'+AppointmentPage._getTimeString(e.date));
                this.formData.date = e.date;
                this.actionNum.value = this.steps.currentStep;
                this._sendData($(this.element).find('[data-date-url]').attr('data-date-url')).then((data)=>{
                    for(let i=0 ;i<data.length; i++){
                        let item = data[i];
                        let time = AppointmentPage._parseDateTimeString(item.VisitStart);
                        time = time[1][0]+':'+time[1][1];
                        item.time = time;
                    }
                    this._timeSelectHundler();
                    this.generateContent(data);
                });
            });

        $('[data-control="datepicker"]').datepicker({
            language: "ru-RU",
            container: "#picker-container",
            autoclose: true
        });
        Inputmask({"mask": "99.99.9999"}).mask($('[data-control="datepicker"]')[0]);
    }
    static _getTimeString(date){
        const h = date.getHours()? date.getHours() : '00';
        const m = date.getMinutes()? date.getMinutes() : '00';
        const s = date.getSeconds()? date.getSeconds(): '00';
        return `${h}:${m}:${s}`;
    }
    static _getDateString(date){
        let month = parseInt(date.getMonth())+1;
        return date.getFullYear()+'-'+month+'-'+date.getDate();
    }
    static _parseDateTimeString(str){
        let calendarDate =  String(str).split('T').map((item) => {
            if(String(item).indexOf('-') + 1){
                return String(item).split('-');
            }
            else {
                return String(item).split(':');
            }
        });
        return calendarDate;
    }
    static  _setDatePickerSettings() {
        $.fn.datepicker.dates["ru"] = {
            days: [
                "Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"
            ],
            daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            daysMin: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
            months: [
                "январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"
            ],
            monthsShort: [
                "Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"
            ],
            today: "Сегодня",
            clear: "Очистить",
            format: "dd.mm.yyyy",
            titleFormat: "MM yyyy",
            weekStart: 1
        };
    }
}
export default init(AppointmentPage, "AppointmentPage");
