import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import "slick-carousel";

class Slider extends Plugin {
  defaults() {}

  init() {
    this.slick = $(this.element).slick({
      nextArrow: $(this.element).closest('.slider').find('[data-slider-arrow="left"]'),
      prevArrow: $(this.element).closest('.slider').find('[data-slider-arrow="right"]'),
      dots: true,
      appendDots: $(this.element)
        .parents(".slider")
        .find(".slider__dots"),
      speed: 600,
      slidesToShow: 3,
      rows: $(this.element).data('rows')? $(this.element).data('rows') : 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    });
  }
}

export default init(Slider, "slider");
