import Plugin, { init } from "@/modules/_utils/Plugin";
import * as parsley from "parsleyjs";
import $ from "jquery";
import PopupMessage from "../popup-message/popup-message";

class AskQuestion extends Plugin {
  defaults() {
    return {
      trigger: `[data-expand="question-form"]`,
      form: `[data-form="question"]`,
      formContainer: `[data-form-container="question"]`,
      formMessage: `[data-form="question-message"]`,
      questionsList: `[data-list="questions"]`,
      fakeQuestion: `[data-fake="fake-question"]`
    };
  }

  init() {
    console.log("init ask question");
    this.informer = new PopupMessage();
    this.isAjax = true;
    this.initFormValidation();
  }

  buildCache() {
    this.trigger = this.element.querySelector(this.options.trigger);
    this.form = this.element.querySelector(this.options.form);
    this.formContainer = this.element.querySelector(this.options.formContainer);
    this.formMessage = this.element.querySelector(this.options.formMessage);
    this.questionsList = this.element.querySelector(this.options.questionsList);
    this.fakeQuestion = this.element.querySelector(this.options.fakeQuestion);
    this.action = this.form.getAttribute("action");
  }

  initFormValidation() {
    let me = this;
    $(this.form)
      .parsley()
      .on("form:submit", function() {
        try {
          if (me.isAjax) me.sendForm();
          else return true;
        } catch (err) {
          console.log(err);
        }
        return false;
      });
  }

  sendForm() {
    let me = this;
    let data = $(this.form).serialize();

    $.ajax({
      type: "POST",
      url: this.action,
      data: data
    })
      .done(function(result) {
        if (result.status == 200) {
          me.informer.showMessage("Спасибо!", result.message);
          me.fillFakeQuestion();
          me.collapseForm();
        }
      })
      .fail(function() {
        console.log("Error!");
      });
  }

  bindEvents() {
    this.trigger.addEventListener("click", event => {
      this.expandForm.call(this, event);
    });
  }

  fillFakeQuestion() {
    const fakeQuestionClone = $(this.fakeQuestion).clone(),
          fakeQuestionText = $(this.fakeQuestion).find('[data-fake="fake-question-text"]'),
          fakeQuestionDate = $(this.fakeQuestion).find('[data-fake="fake-question-date"]');

    $(fakeQuestionText).text($(this.formMessage).val());
    $(fakeQuestionDate).text(this.getDateString(new Date()));
    $(this.fakeQuestion).css('display', 'block');

    this.fakeQuestion = fakeQuestionClone;

    $(fakeQuestionClone).prependTo(this.questionsList);
  }

  getDateString(date) {
    const year = date.getFullYear(),
          month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1,
          day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate(),
          hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours(),
          minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    return `${day}.${month}.${year} в ${hours}:${minutes}`;
  }

  collapseForm() {
    this.form.reset();
    $(this.formContainer).slideUp("fast");
    $(this.trigger).show();
  }

  expandForm(event) {
    event.preventDefault();
    $(this.formContainer).slideDown("fast");
    $(this.trigger).hide();
  }
}

export default init(AskQuestion, "askQuestion");
