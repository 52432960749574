import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import toArray from "@/modules/_utils/dom/toArray";

import $ from "jquery";
import "magnific-popup";

import PopupMessage from "../popup-message/popup-message";

class ImageGallery extends Plugin {
  defaults() {
    return {
      
    };
  }

  init() {
    $(this.element).magnificPopup({
        delegate: 'a', // child items selector, by clicking on it popup will open
        type: 'image',
        gallery: {
            enabled: true
        }
        // other options
    });
  }

}

export default init(ImageGallery, "imageGallery");