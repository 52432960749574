import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import Preloader from '@/modules/preloader/preloader';

class Map extends Plugin {
  defaults() {
    return {
      container: `[data-map="container"]`,
      urlAttr: "data-url",
      mapCenter: [44.14559685289791, 39.18942207617187],
      mapZoom: 7
    };
  }

  init() {
    this.preloader = new Preloader();
    this.preloader.show(this.element);
    const cityString  = window.localStorage.getItem('city');
    if(cityString){
      this.cityData = JSON.parse(cityString);
      this.cityData.coords = String(this.cityData.coords).split(',').map(coord => parseFloat(coord));
      this.offLocation = true;
    }

    ymaps.ready().then(() => {
      this.getData();
    });
  }

  getData() {


    this.markersCollection = new ymaps.Clusterer({
      clusterIconLayout: ymaps.templateLayoutFactory.createClass(
        '<div class="clusterIcon">{{ properties.geoObjects.length }}</div>'
      ),
      clusterIconShape: {
        type: "Circle",
        coordinates: [0, 0],
        radius: 40
      }
    });

    $.ajax({ url: this.url }).done(result => {
      // this.data = JSON.parse(result);
      this.data = result;
     // console.log(this.data);
      this.buildMarkersList();
      this.initMap();
    });
  }

  buildCache() {
    this.url = this.element.getAttribute(this.options.urlAttr);
  }

  static _searchString(str, search){
     return str.toLowerCase().indexOf(search.toLowerCase()) + 1;
  }

  search(str){
    let search = [];
    if(str){
      this.data.map((item) => {
        if (Map._searchString(item.region,str)) {
          item.offices.map((office) => {
            search.push(office);
          });
        }
        else {
          item.offices.map((office) => {
            if (Map._searchString(office.address,str) || Map._searchString(office.title,str)) {
              search.push(office);
            }
          });
        }
      });
    }
    this.showResults(search);
  }

  static _marcSearchString(str,search){
    if(Map._searchString(str,search)){
      const string = String(str);
      const fr = string.toLowerCase().indexOf(String(search).toLowerCase());
      const to = fr + String(search).length;
      const searchString = string.substring(fr,to);
      return string.replace(searchString,`<span>${searchString}</span>`);
    }
      return str;
  }

  showResults(data){
    let list = document.createElement('ul');
    this.resultBlock.html('');
    this.resultBlock.append($(list));
    if(data.length>0){
      this.resultBlock.addClass('state_active');
      data.map((item)=>{
        const li = document.createElement('li');
        li.setAttribute('data-coords',item.coords.join(','));
        li.innerHTML = Map._marcSearchString(`${item.title?  item.title: ''} ${item.address}`,this.searchInput.val()) ;
        $(list).append($(li));
        $(li).on('click', (e) => {
          let coords = $(e.target).data('coords').split(',').map((coord) => {
            return parseFloat(coord);
          });
          $('#suggestAddress').val($(e.target).text());
          this.resultBlock.html('');
          this.resultBlock.removeClass('state_active');
          if($('[data-result="map"]').length) {
            if(!$('[data-result="map"]').hasClass('state_active')){
              this.resultBlock.closest('form').submit();
            }
          }
            this.officesMap.setCenter(coords, 17, {duration: 300});
        });
      });
    } else {
      this.resultBlock.removeClass('state_active');
    }

  }

  static _renderPhoneLinks(str){
    const items = String(str).split(',').map((tel) => {
      const re = new RegExp('-', 'g');
      const re2 = new RegExp(' ', 'g');
      const re3 = new RegExp('\\(', 'g');
      const re4 = new RegExp('\\)', 'g');
      return `<a href="tel:${String(tel).replace(re,'').replace(re2,'').replace(re3,'').replace(re4,'')}">${tel}</a> `;
    });
    return items.join(',');
  }

  buildMarkersList() {
    let officesGeoObjects = [];
    let i = 0;
    for (let itemIndex=0; itemIndex<this.data.length; itemIndex++) {
      let item = this.data[itemIndex];
      for (let index = 0; index < item.offices.length; index++) {
        let _balloonContent = `<div class="baloon-item">
                                  <div class="baloon-icon"><div class="sprite icon-ico-045"></div></div>
                                  <div class="baloon-value">${item.offices[index].address}</div>
                              </div>`;

        if (item.offices[index].phone)
          _balloonContent += `<div class="baloon-item">
                                  <div class="baloon-icon"><div class="sprite icon-ico-069"></div></div>
                                  <div class="baloon-value">${Map._renderPhoneLinks(item.offices[index].phone)}</div>
                              </div>`;
        if (item.offices[index].workTime)
          _balloonContent += `<div class="baloon-item">
                                  <div class="baloon-icon"><div class="sprite icon-ico-049"></div></div>
                                  <div class="baloon-value">${item.offices[index].workTime}</div>
                              </div>`;

        officesGeoObjects[i] = new ymaps.Placemark(
          item.offices[index].coords,
          {
            balloonContentHeader: item.offices[index].title,
            balloonContentBody: _balloonContent,
            balloonContentFooter: item.offices[index].url ? `<a target="_blank" href=${item.offices[index].url} class="button">Перейти</a>` : '',
            hintContent: item.offices[index].title
          },
          {
            iconLayout: "default#image",
            iconImageHref: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAECklEQVRYR72XS0wTQRjHv9nF+iJCYsSKCiWCGjnYk9GDsRYjolEh8RETI3Lx4oMq8WhfeBCNgjHxoAaJB41GIz1oTJUFD6AnHiZGMEbaYmL1Ar54xd0xM2XXoe5jloeTNOl2Zr759f99833fIpjCcHmC2YqI9iJA5QCQDQAeagbjGCAUw4Cbx2UcSbYFY3bNIzsbKIggViNQfIAQAbEazWOyctoOGDfQck/QnSGgVk6QSaAYsC/RErhqRU/muYBWesNHBQS3WYNrXDnygTK3uK7QCZvW59OpgeQQvO6JQ7S9F553vJ8MhaEpIfmrrKAsgVZsq/WIGLeqhhbMd8ihEzvE/aXrTW2//ZCEMxcicl/sq6guxBxQpkAkZrCA+lU3EVVunT8ornTyhE8Ko+ZiBB5F32jwMkJbP70412b0b0yB8ryhBoRQNdm8cIFDeXClUigudE6yNXirEYYaU96c53bDsuvX/jmr7NgNePfxS+p3jGNxKVBgG4iqIwqD6sZLZ/eAnpt4gEhsbT78F1TBUDUg+Zv0oAwVYgM5d0kW7rh3SnctD1C66zDgSKIlQHLYP8MQKK8k1IwA7SU7qio2QOB4qa7KvECveuJwqOaOZiPe4tc92xAo3xtuAwRbiIV7l49oV/vnk6fw4+kzzfDvz5/hdzJJn4WFmeBYXaTNOYpWwWIfDUE6XNtqZx6IVSQVpEw2Y7/rBPm63XXy8Mg4TQNjslKgl8FtKzQdoOI9dcqv4XGBANl2GRtDrMtGO7tgpLNLk548j3Z30+eMpU7I3FWmzc1ZRp53zozL8rxhH0JQPxtBPaVbRoupKFApcnMWKR13q6nU6YP3lrEZG2M4nZD8DXr2TDN1vjccAwS0cs5kYjQKaHKOVekIIoQCZGHmfId8v75STC8dbEylx4yqwOTSAS/jkj/V0OkMUyCnJ+iaKwr96r61BTnyzVr+4vr95yiEr0fhYbRHO9qsbFgqRBbklYSaEKBK1SJRKnhyh7hvu3X7UVMXkXv7/7YfgCEel/wuI3W4gNJVYtWiDdoqJ2ycaNBID0SqerS9j37Sh5U6XEB6Kpn9Q8M5DnW4gYxUsgPGow43EFXJG9JunB0QuhZDT1zyu3n2WfbUqpGJho28Z2XxGGbXWLWt7FpuILJJ7+3DCs6sTNjOQ3ob2D7JCgYAvo3JintWXhTVw9kaZwWEMQ4lpEDQat2UXaZuZN9GTK45dyBPGyj1viZ0q4VXD8pOIE8biAZ4SW25APixHgzG+GpCCvjsuEpda+uWpR/AdpXM3DckK65YW3DovwNNZHDSv2q5SQFUMdByrnkqMLYytdEBbKsL2LzX4YGclsvUA/K9YRLgLrs5Z0YSo54RkptEAZXbzTl6tv4A7C3gNEOrhNAAAAAASUVORK5CYII=",
            iconImageClipRect: [[0, 0], [36, 36]],
            iconImageSize: [36, 36],
            iconImageOffset: [-12, -36]
          }
        );
        i++;
        // this.markersCollection.add(new ymaps.Placemark(item.offices[index].coords));
      }
    }
    this.markersCollection.add(officesGeoObjects);
    this.markersCollection.options.set({
      gridSize: 80,
      clusterDisableClickZoom: false
    });
  }

  initMap() {
    this.officesMap = new ymaps.Map("officessMap", {
      // Координаты центра карты.
      // Порядок по умолчанию: «широта, долгота».
      // Чтобы не определять координаты центра карты вручную,
      // воспользуйтесь инструментом Определение координат.
      center: this.options.mapCenter,
      // Уровень масштабирования. Допустимые значения:
      // от 0 (весь мир) до 19.
      controls: ["geolocationControl", "zoomControl", "typeSelector"],
      zoom: this.options.mapZoom
    });

    // this.suggestView = new ymaps.SuggestView('suggestAddress');
    // this.suggestView.events.add('select', e => {
    //   // var activeIndex = this.suggestView.state.get('activeIndex');
    //   // if (typeof activeIndex == 'number') {
    //     console.log($('#suggestAddress').val(), e);
    //     this.getSearchLocation($('#suggestAddress').val(), coords => {
    //       this.officesMap.setCenter(coords, 18);
    //     });
    //   // }
    // });

    this.searchInput = $('#suggestAddress');
    this.hintTimout = null;
    this.searchInput.on('keyup',(e) => {
      const value = $(e.target).val();
      clearTimeout(this.hintTimout);
      this.hintTimout = setTimeout(()=>{
        this.search(value);
      },500);

    });
    const resultBlock = document.createElement('div');
    resultBlock.classList.add('search-hints');
    this.resultBlock = $(resultBlock);
    this.resultBlock.insertAfter(this.searchInput);
    this.officesMap.geoObjects.add(this.markersCollection);
    this.getLocation.call(this);

    this.preloader.hide();
  }

  getSearchLocation(address, callback) {
    ymaps.geocode(address)
    .then(function (res) {
      callback(res.geoObjects.get(0).geometry.getCoordinates());
    });
  }

  getLocation() {

    const popupBlock = $('.js-popup-location__layout');

    popupBlock.on('click', (e) => {
      const link = $(e.target).closest('.js-popup-location__item');
      if(link){
          const coords = link.data('coords').split(',');
          this.officesMap.setCenter(coords, 10, {duration: 300});
      }
    });

    this.location = ymaps.geolocation;

    if($(this.element).attr('data-all-dot') === 'true'){
      this.officesMap.setBounds(this.officesMap.geoObjects.getBounds(), {checkZoomRange:true});
      return;
    }
    if(this.offLocation){
      this.officesMap.setCenter(this.cityData.coords , 10, {duration: 300});
    } else {
      this.location.get({
        // mapStateAutoApply: true,
        provider: 'yandex'
      })
          .then(
              result => {
                result.geoObjects.options.set('preset', 'islands#redGlyphIcon').set('iconGlyph', 'home').set('iconGlyphColor', 'red');
                if(this.officesMap){
                  // this.officesMap.geoObjects.add(result.geoObjects);
                  this.officesMap.setCenter(result.geoObjects.get(0).geometry.getCoordinates(), 10, {duration: 300});
                }
              },
              err => {
                console.log('Ошибка!', err);
              }
          );
    }
  }
}

export default init(Map, "map");
