import FastClick from "fastclick";
import svg4everybody from "svg4everybody";

import * as serviceWorker from "./helpers/serviceWorker";
import jcf from "jcf";
import $ from "jquery";

import ready from "@/modules/_utils/dom/ready";

import Offcanvas from "@/modules/offcanvas/offcanvas";
import Dropdown from "@/modules/dropdown/dropdown";
import Slider from "@/modules/slider/slider";
import Map from "@/modules/map/map";
import Tabs from "@/modules/tabs/tabs";
import AskQuestion from "@/modules/ask-question/ask-question";
import PopupMessage from "@/modules/popup-message/popup-message";
import PopupLocation from "@/modules/popup-location/popup-location";
import AppointmentPage from "@/modules/making-appointment-page/appoinment-page";
import FileUpload from "@/modules/fileupload/fileupload";
import ReceptionForm from "@/modules/reception/reception";
import ScrollTo from "@/modules/scrollTo/scrollTo";
import Autocomplete from "@/modules/autocomplete/autocomplete";
import OfficesPage from "@/modules/offices-page/offices-page";
import TopService from "@/modules/top-service/top-service";
import ImageGallery from "@/modules/gallery/gallery";
import ZoomImage from "@/modules/zoom-image/zoom-image";
import ClinicInfo from "@/modules/lk-clinic-info/lk-clinic-info";
import Geocode from "@/modules/geocode/geocode";
import {showPreloader, hidePreloader} from "./preloader";
import ArrowTop from "@/modules/arrow-top/arrowtop";
import Share from "@/modules/share-block/share";
import * as  SpecialVersion from "@/modules/special-version/main";

// Factories class based plugins

// Simple functional plugins

Dropdown(".dropdown");
Slider('[data-slider="main"]');
Slider('[data-slider="help"]');
Map('[data-map="container"]');
Offcanvas();
Tabs(".tabs");
AskQuestion("[data-ask-question]");
// PopupMessage();
AppointmentPage("[data-appointment-form]");
FileUpload("[data-file-upload]");
ReceptionForm("[data-reception]");
ScrollTo("[data-scroll-to]");
Autocomplete("[data-autocomplete]");
OfficesPage("[data-offices-page]");
TopService(".top-service");
ImageGallery('.gallery');
ZoomImage('.zoomImage');
ClinicInfo('[data-clinic-form]');
Geocode(".js-chose-location");
ArrowTop();
Share();


function GosLoginDenied() {
  let _hash = window.location.hash;
  if (_hash == '#denied') {
    let pm = new PopupMessage();
    pm.showMessage("Ошибка!", "Портал пациента — это сайт-партнёр портала госуслуг. Для доступа к нашим сервисам зарегистрируйтесь на госуслугах.");
  }
}

ready(function() {
  FastClick.attach(document.body);
  svg4everybody();
  jcf.replace($("[data-custom-control]"));
  GosLoginDenied();
});

// If you want your app to work offline and load faster, you can change
// `unregister()` to `register()` below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

module.exports = {
  AppointmentPage,
  ReceptionForm,
  OfficesPage,
  FileUpload,
  AskQuestion,
  ScrollTo,
  TopService,
  Autocomplete,
  ImageGallery,
  // PopupMessage,
  showPreloader,
  hidePreloader,
  ClinicInfo,
  Dropdown,
  Slider,
  Tabs,
  Map,
  Geocode,
  ArrowTop,
  Share
};
