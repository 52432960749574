import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import toArray from "@/modules/_utils/dom/toArray";

import $ from "jquery";
import * as parsley from "parsleyjs";

import PopupMessage from "../popup-message/popup-message";

class ReceptionForm extends Plugin {
  defaults() {
    return {
      formSelector: '[data-form="reception"]',
      formWrapper: '[data-form-wr]'
    };
  }

  buildCache() {
    this.receptionForm = this.element.querySelector(this.options.formSelector);
    this.formWrapper = this.element.querySelector(this.options.formWrapper);
    console.log(this);
    console.log(this.element);
    console.log(this.options.formSelector);
    console.log(this.receptionForm);
    this.actionUrl = this.receptionForm.getAttribute("action");
  }

  init() {
    this.isAjax = true;
    this.initFormValidation();
  }

  initFormValidation() {
    let me = this;

    this.initCustomValidations();

    $(this.receptionForm)
      .parsley()
      .on("form:submit", function() {
        console.log("form submit");
        try {
          if (me.isAjax) me.sendForm();
          else return true;
        } catch (err) {
          console.log(err);
        }
        return false;
      });
  }

  initCustomValidations() {
    window.Parsley.addValidator(
      "requiredoncheckbox",
      function(value, requirement) {
        // if checkbox with name 'requirement' is checked, field is required
        if ($('[name="' + requirement + '"]').prop("checked") && "" == value) {
          return false;
        }
        return true;
      },
      32
    );
  }

  sendForm() {
    let formData = $(this.receptionForm).serialize();
    let pm = new PopupMessage();
    console.log("sendedData", formData);
    $.ajax({
      url: this.actionUrl,
      type: "POST",
      data: formData
    })
      .done(data => {
        if (data.status == 200) {
          pm.showMessage("Спасибо!", data.message);
          this.formWrapper.innerHTML = `<div class="reception__subtitle">${data.message}</div>`;
          this.receptionForm.reset();
        }
      })
      .fail(() => {
        pm.showMessage(
          "Ошибка!",
          "Произошла непредвиденная ошибка. Попробуйте повторить запрос позднее."
        );
      });
  }
}

export default init(ReceptionForm, "receptionForm");
