import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";

class ApoitmentSteps extends Plugin{
    defaults() {
        return {
            stepSelector: "[data-appointment-step]",
            expandableBody: ".making-appointment-page__body",
        };
    }
    buildCache() {
        this.stepSelector = this.element.querySelectorAll(this.options.stepSelector);
    }
    init() {
        this.currentStep = 0;
        this.stepsCount = this._getStepsCount();
        this._setActiveStep(this.currentStep);
    }
    goToStep(stepNum){
        if(stepNum!==this.currentStep){
            stepNum = this._checkStepRange(stepNum);
            return this._setActiveStep(stepNum);
        } return false;
    }
    _setActiveStep(stepNum){
        let currentStep = this._getStepElement(this.currentStep);
        currentStep.body.slideUp("fast");
        this.currentStep = stepNum;
        currentStep = this._getStepElement(this.currentStep);
        setTimeout(()=>{
            currentStep.body.slideDown("fast", function() {
                $(this).addClass("active");
            });
            this._scrollToStep();
        },200);
        return currentStep;
    }
    _getStepElement(num){
        return {
            step: $(this.element).find('[data-appointment-step="' + num + '"] '),
            body: $(this.element).find('[data-appointment-step="' + num + '"] ' + this.options.expandableBody),
        };
    }
    _checkStepRange(stepNum){
        if (stepNum<0) {
            return 0;
        } else if (stepNum>=this.stepsCount) {
            return this.stepsCount-1;
        } else {
            return stepNum;
        }
    }
    _getStepsCount(){
        return this.stepSelector.length;
    }
    _scrollToStep() {
        let y = $(this.element)
            .find('[data-appointment-step="' + this.currentStep + '"]')
            .offset().top;
        $("body, html").animate(
            {
                scrollTop: y
            },
            500
        );
    }
    getcurrentStepElement(){
        return this._getStepElement(this.currentStep);
    }
    getStepElementByNum(stepNum){
        return this._getStepElement(stepNum);
    }
    setStepEditable(stepNum){
        let currentStep = this._getStepElement(stepNum);
        currentStep.step.removeClass("disable-hover").addClass("editable");
        currentStep.step.bind('click', (e)=>{
            const stepElement = $(e.currentTarget);
            this.goToStep(parseInt(stepElement.data('appointment-step')));
            const event = new Event('stepchange');
            document.dispatchEvent(event);
        });
    }
    resetEditable(){
        for(let i = 0; i<this.stepsCount; i++){
            const currentStep = this._getStepElement(i);
            currentStep.step.addClass("disable-hover").removeClass("editable");
            currentStep.step.unbind('click');
        }
    }
    nextStep(){
        let num = this.currentStep+1;
        num = this._checkStepRange(num);
        return this._setActiveStep(num);
    }
    prevStep(){
        let num = this.currentStep-1;
        num = this._checkStepRange(num);
        return this._setActiveStep(num);
    }
}

export default init(ApoitmentSteps, "ApoitmentSteps");
