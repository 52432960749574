import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import parsley from 'parsleyjs';

class ClinicInfo extends Plugin {
  defaults() {
    return {

    };
  }

  init() {
    this.initCustomValidations();

    $(this.element)
      .parsley()
      .on("form:submit", function() {
        return true;
      });
  }

  initCustomValidations() {
    window.Parsley.addValidator(
      "requiredoncheckbox",
      function(value, requirement) {
        // if checkbox with name 'requirement' is checked, field is required
        if ($('[name="' + requirement + '"]').prop("checked") && "" == value) {
          return false;
        }
        return true;
      },
      32
    );
  }

}

export default init(ClinicInfo, "clinicInfo");