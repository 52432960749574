import debounce from "lodash/debounce";
import Plugin, { init } from "@/modules/_utils/Plugin";
import $ from "jquery";
import autocomplete from 'devbridge-autocomplete';

class Autocomplete extends Plugin {
  defaults() {
      return {
          urlAttribute: 'data-autocomplete'
      }
  }

  buildCache() {
      this.url = this.element.getAttribute(this.options.urlAttribute);
  }

  init() {
    $(this.element).autocomplete({
        serviceUrl: this.url,
        onSelect: function (suggestion) {
            alert('You selected: ' + suggestion.value + ', ' + suggestion.data);
        }
    });
  }
}
export default init(Autocomplete, "autocomplete");